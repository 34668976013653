<template>
  <div class="flex flex-col">
    <div class="w-full last:flex flex-col items-start self-center  mt-8 bg-yellow p-4">
      <h2 class="mb-4 ml-32 text-2xl font-bold pl-6">P03</h2>
      <div class="px-52 flex justify-items-center">
        <v-data-table
          :headers="headers"
          :items="challengesP03"
          :items-per-page="10"
          class="w-full"
          loading-text="Cargando retos"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ new Date(item.createdAt).toLocaleDateString() }}
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <p v-if="item.state=='Rechazado'" class="text-red-500 font-bold text-base">{{ item.state }}</p>
            <p v-if="item.state=='Pendiente'" class="text-blue-600 font-bold text-base">{{ item.state }}</p>
          </template>

          <template v-slot:[`item.invoiceReview`]="{ item }">
            <div class="flex">
              <p v-if="!item.invoiceReview" class="text-green-700 text-base font-bold">Revisado</p>
              <p v-if="item.invoiceReview" class="text-red-500 text-base font-bold">Por revisar</p>
            </div>
          </template>

          <template v-slot:[`item.video`]="{ item }">
            <div class="flex">
              <p v-if="item.video" class="text-green-700 text-base font-bold">Revisado</p>
              <p v-if="!item.video" class="text-red-500 text-base font-bold">Por revisar</p>
            </div>
          </template>

          <template v-slot:[`item.uid`]="{ item }">
            <router-link :to="`/user/${item.uid}`">{{item.uid}}</router-link>
          </template>
          
        <template v-slot:[`item.id`]="{ value }">
          <router-link :to="`/view-invoices/${value}`" class="app-button--3">Ir Validar</router-link>
        </template>

        </v-data-table>
     </div>
    </div>
  <Loader :isLoading="loadingData" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "@/components/Loader";
export default {
  components: { Loader },
  data() {
    return {
      challengesP03: [],
      headers: [
        {
          text: "Id del reto",
          align: "start",
          value: "challengeId",
        },
        { text: "Usuario", value: "uid" },
        { text: "Fecha", value: "createdAt" },
        { text: "Estado", value: "state" },
        { text: "Facturas", value: "invoiceReview" },
        { text: "Video", value: "video" },
        { text: "Ver Facturas", value: "id" },
      ],
      loadingData: true,
      viewInvoices: false,
    };
  },
    methods: {
    ...mapActions([
      "challenges/getDCMChallengesP03",
    ]),
    },
      async mounted() {
    const res = await this["challenges/getDCMChallengesP03"]();
    if (res.success) {
      this.challengesP03 = res.challengesP03;
    }
    this.loadingData = false;
    this.isLoading = false;
  },
};
</script>